import { request } from "../utils/axios";
import {
    getSessionId,
    getSessionData,
    getShadowRoot,
} from "../utils/store";
import { getConsent, disclosureAndConsentRequest } from "../utils/StepService";
import { setLoading } from "../utils";
import flatpickrCss from "flatpickr/dist/themes/dark.css";

class DisclosureAndConsent {

    render = async () => {
        let consentDetails = await this.GetConsentAndDisclosure();
        const sessionData = getSessionData();
        if (sessionData?.profileDetails?.showFromUpdateInfo === true) {
            this.personalInfoObj = await this.getProfileDetails();
        }
        let modalBox = getShadowRoot().querySelector("#identity-modal__box");

        if (modalBox.querySelector("#agreement")) return;

        const flatpickrStyleSheet = document.createElement("style");
        flatpickrStyleSheet.textContent = flatpickrCss;
        getShadowRoot().appendChild(flatpickrStyleSheet);

        modalBox.insertAdjacentHTML(
            "beforeend",
            `<style>    
        .identity__row {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically if needed */
}
</style>

<form class="form-content" id="agreement" data-form-tab>
    <div class="identity__col-12">
        <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
            <button data-close="close" style="display:none;" type="button">&times;</button>
            <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
            <div id="identity-alert__message" data-text="text"></div>
        </div>
    </div>
    <div id="scrollableContent" class="scrollable-content identity-field floating-field">
        <div id="disclosureAndConsent" class="identity-field floating-field">
        ${consentDetails.content || ""}
        </div>
           </div>
 <div class="consent-box" style="margin-top:20px">
             <div class="identity__col-12">
            <div class="identity-field floating-field">
                <input type="checkbox" id="agreeCheckbox"  class="identity-input-checkbox" disabled>
                <label for="termsprivacy" class="identity-floating-checkbox-label">I agree to the  <a class="identity-anchortag" href="https://ftxidentity.com/terms-of-use/" target="_blank" id="termsLink">Terms of Use</a> and
                 <a class="identity-anchortag" href="https://ftxidentity.com/privacy-policy/" target="_blank" id="privacyPolicy">Privacy Policy</a></label>
            </div>
            </div>
            <div class="identity__col-12">
                <div class="identity__row column-reverse-xs">
                    <div class="identity__col-6">
                        <div class="identity-field">
                            <button id="btn-continue" type="submit"
                                class="identity-btn btn-continue"
                                disabled>Continue
                                <span class="loading-icon">
                                    <svg class="svg" width="20px" height="20px"
                                        aria-hidden="true" role="img">
                                        <use href="#svg__loader"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            xlink:href="#svg__loader"></use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

           </form>
`
        );
        this.bindEvents(consentDetails);
    };

    async bindEvents(consentDetails) {

        const disclosureAndConsentForm =
            getShadowRoot().querySelector(`#agreement`);

        // Manage button state
        let IsAgreed = disclosureAndConsentForm.querySelector("#agreeCheckbox");
        IsAgreed.addEventListener("change", function () {
            disclosureAndConsentForm.querySelector("#btn-continue").disabled = !this.checked;
        });

        // Manage checkbox state
        let contentBox = disclosureAndConsentForm.querySelector('#scrollableContent');
        contentBox.addEventListener('scroll', function () {
            let scrollTop = contentBox.scrollTop + 1;
            let clientHeight = contentBox.clientHeight;
            let scrollHeight = contentBox.scrollHeight;

            if (Math.floor(scrollTop + clientHeight) >= scrollHeight) {
                disclosureAndConsentForm.querySelector('#agreeCheckbox').disabled = false;
            }
        });

        //Trigger on submit
        disclosureAndConsentForm.addEventListener("submit", (event) => {
            event.preventDefault();
            setLoading(true);
            disclosureAndConsentRequest(consentDetails, IsAgreed.checked);
        });
    }

    //Get DisclosureAndConsent 
    async GetConsentAndDisclosure() {
        let data = await getConsent("DisclosureAndConsent");
        if (data) {
            return data;
        }
    }
}

export default DisclosureAndConsent;
