import style from "./css/App.scss";
import { getLocalIP, getBrowserInfo,setMainLoader } from "./utils";
import { request } from "./utils/axios";
import { nextPrev } from "./utils/StepService";
import {
  hideShowDialog,
  getDialogState,
  setFullScreen,
  setRedirectUri,
  setErrorCallback,
  setSuccessCallback,
  setCloseCallback,
  setCompleteCallback
} from "./utils/DialogService";
import {
  setSessionId,
  getSessionId,
  setSessionData,
  getProfileToken,
  setProfileToken,
  setShadowRoot,
  setApiKey,
  setinitSessionOptions,
  getApiKey,
  getinitSessionOptions,
  getshowClose,
  setshowClose,
  getIsIdentityCalled,
  setIsIdentityCalled,
  setEventType,
  setElement,
  setPlatform,
  getPlatform,
  getinitBillingAddr,
  setinitBillingAddr,
  setallowEditing,
  getallowEditing,
} from "./utils/store";
import { closeSession } from "./utils/StepService";
import axios from "axios";

class App {
  isOpen = false;
  styleOptions = null;
  logoUrl = null;
  eventInit=[];

  constructor(
    options = {
      fullScreen: false,
      sessionId: null,
      profileToken: null,
      styleOptions: null,
      sdkOrigin: null,
      initSessionOptions : null,
      apiKey : null,
      logoUrl:null,
      collectPersonalInfo :true,
      personalInfo:null,
      verificationType:0,
      platform:null,
      allowEditing: true
    }
  ) {
    setFullScreen(options.fullScreen);
    setSessionId(options.sessionId);
    setErrorCallback(options.onError);
    setProfileToken(options.profileToken);
    setSuccessCallback(options.onSuccess);
    setApiKey(options.apiKey);
    setCloseCallback(options.onClose);
    setCompleteCallback(options.onComplete);
    setPlatform(options.platform);
    setallowEditing(options.allowEditing);
    getLocalIP();
    getBrowserInfo();
    if (options.styleOptions) this.styleOptions = options.styleOptions;
    if(options.logoUrl) this.logoUrl= options.logoUrl
    options.mode= (options.mode === null|| options.mode === undefined) ? "auto" : options.mode
    
   console.log(options.platform);
   if(options.apiKey!== null && options.apiKey!== undefined && (options.initSessionOptions === null || options.initSessionOptions === undefined) && (getSessionId()|| options.verificationType||options.collectPersonalInfo|| options.personalInfo))
    {
      options.initSessionOptions={
        "sessionId": getSessionId(),
        "collectPersonalInfo": options.collectPersonalInfo,
        "personalInfo": options.personalInfo,
        "verificationType": options.verificationType
      }
      setSessionId(null);
    }
    setinitSessionOptions(options.initSessionOptions);

    if(options.mode === "auto" &&  options.element != null && options.element !== undefined){
      options.showClose= (options.showClose === null|| options.showClose === undefined) ? false : options.showClose
      setshowClose(options.showClose);

      if(options.events===null || options.events===undefined){
        options.events = ["mousedown","click","touchstart"]
      }

      if(getPlatform() && getPlatform()==="magento2"){
        this.importBillingAddress(options.personalInfo)
      }
    setInterval(()=>this.initializeStartEvent(options,this), 50);
  }

}

  initializeStartEvent(options, obj) {
    if(Object.prototype.toString.call(options.element) !== '[object Array]') {
        options.element= [options.element]
    }

    for (var t = 0; t < options.element.length; t++) {
      var elementName = options.element[t];
      var eventBound = function(selectedElement, selectedElementName) {
        if (selectedElement) {
          if (!obj.eventInit[selectedElementName]) {
            obj.eventInit[selectedElementName] = { bound: false };
          } else if (obj.eventInit[selectedElementName].name && selectedElement.className.indexOf(obj.eventInit[selectedElementName].name) === -1) {
            obj.eventInit[selectedElementName].bound = false;
          }
  
          if (obj.eventInit[selectedElementName].bound || selectedElement.className.indexOf("ftxidentity_")>-1) {
            return;
          }
  
          var i = "ftxidentity_" + Math.floor(1e3 + 9e3 * Math.random());
          selectedElement.className += " " + i;
          obj.eventInit[selectedElementName] = {
            name: i,
            bound: true,
            dom_element: selectedElement,
          };

          selectedElement.innerText = "VERIFY AGE & "+ selectedElement.innerText.trim();
          
          options.events.forEach((event) => {
            selectedElement.addEventListener(event, (e) => {
              console.log("Event listener added to element: " + e);
              
              const ftxIdentityClass = e?.target?.className?.split(" ")?.filter((className) => className.startsWith('ftxidentity_'));
              setElement("."+ ftxIdentityClass[0]);
                obj.initClick(e, options);
            }, true);
          });
        }
      };
    }
  
    var data = document.querySelectorAll(elementName);
    if (data.length > 1) {
      for (var r = 0; r < data.length; r++) {
        eventBound(data[r], elementName + "_" + r);
      }
    } else {
      if (document.querySelector(elementName)) {
        if (obj.eventInit[elementName] === undefined) {
          obj.eventInit[elementName] = {
            bound: false,
          };
        }
        eventBound(document.querySelector(elementName), elementName);
      }
    }
  }

  getRedirectUri() {
    request
      .get(`/redirectUri/${getSessionId()}`)
      .then(({ data }) => {
        setRedirectUri(data.data);
      })
      .catch(() => {
        var dialogState = getDialogState();
        closeSession();
        dialogState.errorCallback();
      });
  }

  getSessionDetail = () => {
    return request
      .get(`/Details/${getSessionId()}`)
      .then(({ data }) => {
        if (data.data) {
          setSessionData(
            {
              ...data.data,
              ownershipVerificationDetails:
                data.data.profileDetails &&
                data.data.profileDetails.ownershipVerificationDetails,
            },
            true
          );
        }

        // if (data.data)
        // setSessionData({
        //   ...data.data,
        //   //     isProfileFound: true,
        //   profileDetails: {
        //     ...data.data.profileDetails,
        //     // isVerified: true,
        //     hasFTxCoreLogin: true,
        //     //       // showFromPublicData: true,
        //   },
        // });

        return data;
      })
      .catch((error) => {
        if (!error?.response?.data?.errors?.SUPPORT_VERIFICATION_IN_PROGRESS) {
          // closeSession();
          var dialogState = getDialogState();
          closeSession();
          dialogState.errorCallback();
        }
      });
  };

  importBillingAddress = (personalInfo) => {
      if (window.requirejs) {
        window.requirejs(["Magento_Checkout/js/model/quote", "Magento_Checkout/js/action/create-billing-address", "Magento_Checkout/js/action/select-billing-address", "Magento_Checkout/js/checkout-data"],
        function(e) {
          var initPersonInfo = function(e) {
            if (e &&(personalInfo === null || personalInfo === undefined)) {
              var initSessionOptions = {
                collectPersonalInfo: true,
                personalInfo: {
                  firstName: e.firstname,
                  lastName: e.lastname,
                  city: e.city,
                  state: e.regionCode,
                  zip: e.postcode,
                  addressLine1: e.street ? e.street[0] : "",
                  addressLine2: getAddressLine2(e.street),
                  phone: e.telephone!==null && e.telephone !== '' &&  e.telephone !== undefined ? e.telephone.replace(/\D/g, "").replace(/^0+/, ""):""
                }
              }
              setinitBillingAddr(initSessionOptions);
            }
          }
          e.billingAddress.subscribe(function(e) {
            try {
              e && initPersonInfo(e);
            } catch(e) {
              console.log(e);
            }
          });
        },
        )

       function getAddressLine2(value){
        if(Array.isArray(value) && value.length===3){
          if(value[1] !=="" && value[1]!== null  && value[1] !== undefined  && value[2]!=="" && value[2]!== null && value[2]!== undefined)
            return value[1] + ', '+value[2]
           else if(value[1] !== null && value[1] !== ""  && value[1] !== undefined)
              return value[1];
            else if(value[2] !=="" && value[2]!== null  && value[2] !== undefined)
              return value[2]
          }
          else if(value.length === 2 && value[1] !== null && value[1] !=="" && value[1] !== undefined){
            return value[1];
          }
          else 
          return ""
        }
    }
    }

    initClick(e, options){
      if(!getIsIdentityCalled()){
        e.stopPropagation();
        e.preventDefault();
        setEventType(e.type);

        if(options.apiKey!= null && (getApiKey() === undefined || getApiKey() === null)){
        setFullScreen(options.fullScreen);
        setSessionId(options.sessionId);
        setErrorCallback(options.onError);
        setCloseCallback(options.onClose);
        setCompleteCallback(options.onComplete);
        setProfileToken(options.profileToken);
        setSuccessCallback(options.onSuccess);
        setApiKey(options.apiKey);
        setinitSessionOptions(options.initSessionOptions);
        setshowClose(options.showClose);
        setPlatform(options.platform)
        setallowEditing(options.allowEditing)
  
        if((options.initSessionOptions === null || options.initSessionOptions=== undefined) && (getSessionId()|| options.verificationType||options.collectPersonalInfo|| options.personalInfo))
         {
           options.initSessionOptions={
             "sessionId": getSessionId(),
             "collectPersonalInfo": options.collectPersonalInfo,
             "personalInfo": options.personalInfo,
             "verificationType": options.verificationType
           }
           setSessionId(null);
         }
         setinitSessionOptions(options.initSessionOptions);
       }
        
        this.start();
       }
       else if(getPlatform() && getPlatform()==='magento2'){
         var n = document.querySelector(".billing-address-same-as-shipping-block");
         if(n && n.style.display!== 'none'){
          setEventType(null);
          setElement(null);
          setinitBillingAddr(null);
          setIsIdentityCalled(false);
        }
       }
        else{
          setEventType(null);
          setElement(null);
          setIsIdentityCalled(false);
        }
    }
        
  start = () => {
    if (!getProfileToken() && !getSessionId() && !getApiKey()) return false;

    if(!getProfileToken() || (!getApiKey() && getApiKey() !== undefined)){
      if(!getSessionId()){
        let {API_ROOT_URI , FTX_API_KEY} = process.env;
        let anyError = false;

        var initSession = getinitSessionOptions();
        if(getApiKey()){
          FTX_API_KEY= getApiKey();
          if(getinitBillingAddr()){
            initSession= getinitBillingAddr();
            initSession.allowEditing= getallowEditing()?? true;
          }
         else if (initSession == null){
            initSession= {
              "DisclosureAndConsent": true,
              }
            } 
        }

        axios.post(`${API_ROOT_URI}/Init`,initSession ,{
          headers: {
            FTxIdentityApiKey: FTX_API_KEY,
          }}).then((data)=>{
          if (data.data && data.data.data.sessionId) {
            setSessionId(data.data.data.sessionId);
            this.render().then(()=>{
              hideShowDialog(true);
        
              document.querySelector("*").addEventListener("click", (event) => {
                let { dataset } = event.target;
          
                if (dataset.btnPrevious || dataset.btnNext || dataset.modalClose) {
                  event.stopImmediatePropagation();
                }
          
                if (dataset.btnPrevious) nextPrev(-1);
          
                if (dataset.btnNext) nextPrev(1);

                if (dataset.modalClose) {
                  var dialogState = getDialogState();
                  hideShowDialog(false);
                  dialogState.errorCallback();
                }
              });
            });
          }
        }).catch((error)=>{
          anyError = true;
          getDialogState().errorCallback(error);
        });

        if(anyError){
          return;
        }
      }
    }
    if((getSessionId()!= null && getSessionId()!== undefined) ||(getProfileToken()!= null && getProfileToken() !== undefined)){
    this.render().then(()=>{
      hideShowDialog(true);

      document.querySelector("*").addEventListener("click", (event) => {
        let { dataset } = event.target;

        if (dataset.btnPrevious || dataset.btnNext || dataset.modalClose) {
          event.stopImmediatePropagation();
        }

        if (dataset.btnPrevious) nextPrev(-1);

        if (dataset.btnNext) nextPrev(1);

        if (dataset.modalClose) {
          var dialogState = getDialogState();
          hideShowDialog(false);
          dialogState.errorCallback();
        }
      });
    });
  }

  };

  render = async () => {
    var dialogState = getDialogState();

    if (!document.getElementById("identity-container")) {
      var container = document.createElement("div");

      container.className = "identity-container";

      container.id = "identity-container";

      let shadowRoot = container.attachShadow({ mode: "closed" });
      setShadowRoot(shadowRoot);

      const styleSheet = document.createElement("style");
      styleSheet.textContent = style;

      shadowRoot.innerHTML = `<div id="identityModal" class="${
        dialogState.fullScreen ? "identity-full-screen" : ""
      } identity-modal ${
        this.styleOptions ? "custom-dialog" : ""
      }"><div class="identity-modal__container" id="identityModalContainer"  style="${
        this.styleOptions
          ? `height:${this.styleOptions.height};top:${this.styleOptions.top};`
          : ""
      }">
                                   <div id="identity__svg">
                                      <svg style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                          <defs>
                                              <symbol id="svg__upload" viewBox="0 0 24 18"><path d="M11.49,7.17l-2.5,3.06-.74-.68,3.74-4.56,3.75,4.59-.75,.66-2.5-3.08v7.83h-1V7.17Zm7.01,9.83H5.5c-2.48,0-4.5-2.02-4.5-4.5,0-2.18,1.55-4.04,3.7-4.42l.78-.14,.04-.79c.18-3.45,3.03-6.15,6.48-6.15s6.3,2.7,6.48,6.15l.04,.79,.78,.14c2.14,.39,3.7,2.25,3.7,4.42,0,2.48-2.02,4.5-4.5,4.5m.98-9.91c-.21-3.95-3.47-7.09-7.48-7.09S4.73,3.14,4.52,7.09C1.95,7.56,0,9.8,0,12.5c0,3.04,2.46,5.5,5.5,5.5h13c3.04,0,5.5-2.46,5.5-5.5,0-2.7-1.95-4.94-4.52-5.41" fill="currentColor" style="fill-rule:evenodd;"/></symbol>
                                              <symbol id="svg__calendar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 40">
                                                  <path fill="currentColor"  d="M16,24v-4h4v4h-4Zm-8,0v-4h4v4h-4Zm16,0v-4h4v4h-4Zm-8,8v-4h4v4h-4Zm-8,0v-4h4v4h-4Zm16,0v-4h4v4h-4ZM0,40V3H6.25V0h3.25V3H26.5V0h3.25V3h6.25V40H0Zm3-3h30V15.5H3v21.5Zm0-24.5h30V6H3v6.5Zm0,0v0Z"/>
                                              </symbol>
                                              <symbol id="svg__camera" viewBox="0 0 24 20"><path d="M17,0l1.41,2.11c.37,.56,1,.89,1.66,.89h3.93V20H0V3H5.93c.67,0,1.29-.33,1.66-.89L9,0h8Zm3.07,4c-1.01,0-1.94-.5-2.5-1.34l-1.11-1.66h-6.93l-1.11,1.66c-.56,.84-1.49,1.34-2.5,1.34H1v15H23V4h-2.93Zm-7.05,1c3.31,0,6,2.69,6,6s-2.69,6-6,6-6-2.69-6-6,2.69-6,6-6Zm0,1c2.76,0,5,2.24,5,5s-2.24,5-5,5-5-2.24-5-5,2.24-5,5-5Zm0,2c1.66,0,3,1.34,3,3s-1.34,3-3,3-3-1.34-3-3,1.34-3,3-3Zm0,1c1.1,0,2,.9,2,2s-.9,2-2,2-2-.9-2-2,.9-2,2-2ZM5.02,7c0-.55-.45-1-1-1s-1,.45-1,1,.45,1,1,1,1-.45,1-1ZM2.02,1h3v1H2.02V1Z" fill="currentColor" style="fill-rule:evenodd;"/></symbol>
                                              <symbol id="svg__success" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" style="fill:#75be43;"/><path d="M217.47,341.62c4.55,4.55,12.34,4.55,16.89,0l138.35-137.7c4.55-4.55,4.55-12.34,0-16.89l-16.89-16.89c-4.55-4.55-12.34-4.55-16.89,0l-113.02,112.37-52.61-52.61c-4.55-4.55-12.34-4.55-16.89,0l-16.89,16.89c-4.55,4.55-4.55,12.34,0,16.89l77.94,77.94h0Z" style="fill:#fff;"/></symbol>
                                              <symbol id="svg__error" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" style="fill:currentColor;"/><path d="M346,324.25l-69-68.25,68.25-69-21-21-68.25,69-69-68.25-21,21,69,68.25-68.25,69,21,21,68.25-69,69,68.25,21-21h0Z" style="fill:#fff;"/></symbol>
                                              <symbol id="svg__alert-success" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" style="fill:#75be43; opacity:.8;"/><path d="M221.49,333.12c4.2,4.2,11.02,4.2,15.22,0l123.78-123.78c4.2-4.2,4.2-11.02,0-15.22l-15.22-15.22c-4.2-4.2-11.02-4.2-15.22,0l-100.95,101.01-47.15-47.19c-4.2-4.2-11.02-4.2-15.22,0l-15.22,15.22c-4.2,4.2-4.2,11.02,0,15.22l69.98,69.96Z" style="fill:#03101b;"/></symbol>
                                              <symbol id="svg__alert-error" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" style="fill:#f65f5f; opacity:.8;"/><path d="M355.71,331.42l-76.31-75.58,75.56-76.22-23.53-23.33-75.54,76.27-76.24-75.52-23.35,23.35,76.31,75.66-75.56,76.29,23.35,23.35,75.78-76.37,76.2,75.62,23.33-23.53Z" style="fill:#03101b;"/></symbol>
                                              <symbol id="svg__alert-warning" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" style="fill:#f4c050; opacity:.8;"/><path d="M234.88,139.81v147.88h42.25V139.81h-42.25Zm0,232.38h42.25v-42.25h-42.25v42.25Z" style="fill:#03101b; fill-rule:evenodd;"/></symbol>
                                              <symbol id="svg__alert-info" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" style="fill:#0075ff; opacity:.8;"/><path d="M235.01,371.42V224.52h41.97v146.9h-41.97Zm0-230.84h41.97v41.97h-41.97v-41.97Z" style="fill:#03101b; fill-rule:evenodd;"/></symbol>
                                              <symbol id="svg__pencil" viewBox="0 0 24 24"><path fill="currentColor" d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"/></symbol>
                                              <symbol id="svg__crop" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve"><path fill="currentColor" d="M34.8,31.8V13.2H16.2v-3h21.5v21.5H34.8z M34.8,46v-8.2H10.2V13.2H2v-3h8.2V2h3v32.8H46v3h-8.2V46H34.8z"/></symbol>
                                              <symbol id="svg__loader" style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto;"  viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                                    <circle cx="40" cy="40" r="0" fill="none" stroke="currentColor" stroke-width="8">
                                                                    <animate attributeName="r" repeatCount="indefinite" dur="1.5384615384615383s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="0s"></animate>
                                                                    <animate attributeName="opacity" repeatCount="indefinite" dur="1.5384615384615383s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="0s"></animate>
                                                                    </circle><circle cx="40" cy="40" r="0" fill="none" stroke="currentColor" stroke-width="8">
                                                                    <animate attributeName="r" repeatCount="indefinite" dur="1.5384615384615383s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="-0.7692307692307692s"></animate>
                                                                    <animate attributeName="opacity" repeatCount="indefinite" dur="1.5384615384615383s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="-0.7692307692307692s"></animate>
                                                                    </circle>
                                                </symbol>
                                                <symbol id="svg__close" viewBox="0 0 50 50" style="fill:currentColor;"><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path></symbol>
                                                <symbol id="svg__refresh" viewBox="0 -960 960 960" style="fill:currentColor;"><path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z"/></symbol>
                                                <symbol id="svg__id-card" viewBox="0 0 135.844 105.656">
                                                    <path d="M124.523,2.25H11.32A11.323,11.323,0,0,0,0,13.57v3.773H135.844V13.57A11.323,11.323,0,0,0,124.523,2.25ZM0,96.586a11.323,11.323,0,0,0,11.32,11.32h113.2a11.323,11.323,0,0,0,11.32-11.32v-71.7H0ZM83.016,41.871A1.892,1.892,0,0,1,84.9,39.984h33.961a1.892,1.892,0,0,1,1.887,1.887v3.773a1.892,1.892,0,0,1-1.887,1.887H84.9a1.892,1.892,0,0,1-1.887-1.887Zm0,15.094A1.892,1.892,0,0,1,84.9,55.078h33.961a1.892,1.892,0,0,1,1.887,1.887v3.773a1.892,1.892,0,0,1-1.887,1.887H84.9a1.892,1.892,0,0,1-1.887-1.887Zm0,15.094A1.892,1.892,0,0,1,84.9,70.172h33.961a1.892,1.892,0,0,1,1.887,1.887v3.773a1.892,1.892,0,0,1-1.887,1.887H84.9a1.892,1.892,0,0,1-1.887-1.887ZM41.508,39.984A15.094,15.094,0,1,1,26.414,55.078,15.108,15.108,0,0,1,41.508,39.984ZM15.825,88.143A15.115,15.115,0,0,1,30.188,77.719h1.934a24.292,24.292,0,0,0,18.773,0h1.934A15.115,15.115,0,0,1,67.191,88.143a3.714,3.714,0,0,1-3.679,4.67H19.5A3.722,3.722,0,0,1,15.825,88.143Z" transform="translate(0 -2.25)" fill="#183956"/>
                                                </symbol>
                                                <svg id="svg__phone" viewBox="0 0 62.454 105.691">
                                                    <path id="mobile" d="M57.944,1.5H19.51A12.015,12.015,0,0,0,7.5,13.51V95.181a12.015,12.015,0,0,0,12.01,12.01H57.944a12.015,12.015,0,0,0,12.01-12.01V13.51A12.015,12.015,0,0,0,57.944,1.5ZM38.727,102.387a7.206,7.206,0,1,1,7.206-7.206A7.2,7.2,0,0,1,38.727,102.387ZM60.346,83.171H17.108V15.912H60.346Z" transform="translate(-7.5 -1.5)" fill="#183956"/>
                                                </svg>
                                                <svg id="svg__phone-success" viewBox="0 0 62.454 105.691">
                                                  <g id="success-phone" transform="translate(-170 -113.154)">
                                                    <path id="mobile" d="M57.944,1.5H19.51A12.015,12.015,0,0,0,7.5,13.51V95.181a12.015,12.015,0,0,0,12.01,12.01H57.944a12.015,12.015,0,0,0,12.01-12.01V13.51A12.015,12.015,0,0,0,57.944,1.5ZM38.727,102.387a7.206,7.206,0,1,1,7.206-7.206A7.2,7.2,0,0,1,38.727,102.387ZM60.346,83.171H17.108V15.912H60.346Z" transform="translate(162.5 111.654)" fill="#183956"/>
                                                    <path id="check-circle" d="M18,3A15,15,0,1,0,33,18,15.005,15.005,0,0,0,18,3ZM15,25.5,7.5,18l2.115-2.115L15,21.255,26.385,9.87,28.5,12Z" transform="translate(183 145)" fill="#4aac27"/>
                                                  </g>
                                                </svg>

                                          </defs>
                                      </svg>
                                  </div>
                                <div class="identity-modal__content">
                                    <div class="identity-modal__header"></div>
                                    <div class="identity-modal__body">
                                      ${
                                        getshowClose() || (getshowClose() === undefined && !dialogState.fullScreen)
                                          ? `
                                          <button class="identity-model__close" data-modal-close="true" type="button" id="btnClose">
                                            <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img" data-modal-close="true">
                                              <use href="#svg__close" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__close" data-modal-close="true">
                                              </use>
                                            </svg>
                                          </button>`
                                          : ""
                                      }
                                      <div class="identity-modal__inner">
                                        <div class="identity-modal__aside">
                                        ${this.logoUrl? ` <div><image src= `+this.logoUrl+`/></div>`:` <div class="identity__logo"></div>` }
                                          <div class="identity__h1">Age Verification</div>
                                          <div class="identity__p" id="identityLeftMessage">Please verify your account information to continue Age Verification.</div>
                                        </div>
                                        <div class="identity-modal__form">
                                          <div class="identity-modal__box" id="identity-modal__box">
                                            <div class="dialog-loader" id="mainLoader">
                                              <svg class="svg" width="100px" height="100px" aria-hidden="true" role="img">
                                                <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader">
                                                </use>
                                              </svg>
                                              <div id="dialog-loader-text">Searching your profile, it could take minute.</div>
                                            </div>
                                            <!-- Step Wizard -->
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                </div>
                                <div data-modal-backdrop="true" class="identity-modal__backdrop"></div>
                            </div> `;

      shadowRoot.appendChild(styleSheet);

      shadowRoot.querySelector("#btnClose")?.addEventListener("click",()=>{
        closeSession();
      })

      // setContainer(container);
      document.body.appendChild(container);

    }

    setMainLoader(true);
    if (getSessionId()) {
      if (dialogState.fullScreen) await this.getRedirectUri();
      await this.getSessionDetail();
    }

    nextPrev(0);
  };

  // otpValidate = () => {
  //   let isOtpValid = true;
  //   const otpInput = document.querySelectorAll(".otpInput");
  //   for (const input of otpInput) {
  //     if (input.value.trim() === "") {
  //       isOtpValid = false;
  //     }
  //   }
  //   return isOtpValid;
  // };

  // checkPersonalInfo() {
  //   let ispersonalInfoFilled = true;
  //   var personalInfoInputs = document.querySelectorAll(".pi-input.required");

  //   for (const input of personalInfoInputs) {
  //     if (
  //       input.value.trim() === "" ||
  //       (input.name === "phonenumber" && input.value.length !== 10)
  //     ) {
  //       ispersonalInfoFilled = false;
  //     }
  //   }
  //   return ispersonalInfoFilled;
  // }

  // checkOTP(e) {
  //   if (
  //     ((e.keyCode === 86 || e.keyCode === 67) &&
  //       (e.ctrlKey === true || e.metaKey === true)) ||
  //     e.keyCode === 8 ||
  //     e.keyCode === 13 ||
  //     (e.keyCode > 47 && e.keyCode < 58) ||
  //     (e.keyCode > 95 && e.keyCode < 106)
  //   ) {
  //     const otpInput = document.querySelectorAll(".otpInput");

  //     otpInput.forEach((input) => {
  //       input.addEventListener("paste", function (ev) {
  //         const clip = ev.clipboardData.getData("text/plain");
  //         // Allow numbers only
  //         if (!/\d{6}/.test(clip)) return ev.preventDefault(); // Invalid. Exit here
  //         // Split string to Array or characters
  //         const s = [...clip];
  //         // Populate inputs. Focus last input.
  //         otpInput.forEach((input, i) => {
  //           input.value = s[i];
  //           input.focus();
  //         });
  //       });
  //     });
  //   } else {
  //     e.preventDefault();
  //   }
  // }

  // navigationOTP(e) {
  //   let isvalidOTP = true;
  //   if (
  //     e.target.value.length >= 1 &&
  //     e.srcElement.nextElementSibling !== null
  //   ) {
  //     e.srcElement.nextElementSibling.focus();
  //   }
  //   if (
  //     e.target.value.length === 0 &&
  //     e.srcElement.previousElementSibling !== null
  //   ) {
  //     e.srcElement.previousElementSibling.focus();
  //   }

  //   var otpInputsList = document.querySelectorAll(".otpInput");
  //   for (const otpInputList of otpInputsList) {
  //     if (otpInputList.value.trim() === "" || otpInputList.value.length === 0) {
  //       isvalidOTP = false;
  //     }
  //   }

  //   return isvalidOTP;
  // }
}

export default App;
