import axios from "axios";
import qs from "qs";
import {
  throttle,
  supportBtnContent,
  setError,
  getErrorHtmlFromResponse,
} from "../utils";
import { closeSession } from "./StepService";
import { getDialogState } from "../utils/DialogService";
import {  getSessionData, getShadowRoot, setSessionData } from "./store";

const {
  API_ROOT_URI,
  IDENTITY_URL,
  CLIENT_CREDENTIALS_CLIENT_ID,
  CLIENT_CREDENTIALS_SECRET,
  CLIENT_CREDENTIALS_GRANT_TYPE,
} = process.env;

export let token;

export const request = axios.create({
  baseURL: API_ROOT_URI,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const identityRequest = axios.create({
  baseURL: IDENTITY_URL,
  withCredentials: false,
});

export const setToken = (jwtToken) => {
  token = jwtToken;
};

const getToken = () => {
  if (!token) {
    return identityRequest
      .post(
        "connect/token",
        qs.stringify({
          client_id: CLIENT_CREDENTIALS_CLIENT_ID,
          client_secret: CLIENT_CREDENTIALS_SECRET,
          grant_type: CLIENT_CREDENTIALS_GRANT_TYPE,
        })
      )
      .then(({ data }) => {
        token = data.access_token;
        setAxiosHeaders(token);
      });
  } else setAxiosHeaders(token);
};

const handleTokenExpire = throttle(async (originalRequest, error) => {
  try {
    token = "";
    await getToken();
    if (!token) return Promise.reject(error);
    originalRequest.headers["Authorization"] = `Bearer ${token}`;
    return request(originalRequest);
  } catch (err) {
    console.log(err);
  }
}, 1500);

request.interceptors.request.use(
  async (config) => {
    // if (!config.headers["Authorization"]) {
    await getToken();
    config.headers["Authorization"] = `Bearer ${token}`;
    // }

    return config;
  },
  (error) => {
    return error;
  }
);

request.interceptors.response.use(
  (response) => response,
  async (error) => {
    // return Error(error);
    const sessionData = getSessionData();
    const originalRequest = error.config;
    if (
      error?.response?.status === 400 &&
      error?.response?.data?.errors?.INVALID_SESSION
    ) {
      var dialogState = getDialogState();
      closeSession();
      dialogState.errorCallback("Invalid session! or session expired!");
      return;
    } else if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return await handleTokenExpire(originalRequest, error);
    } else if (
      error?.response?.data?.errors?.SUPPORT_VERIFICATION_IN_PROGRESS &&
      !sessionData?.showSupportVerificationError
    ) {
      const parent = getShadowRoot().querySelector("#identity-modal__box");
      if (parent) {
        while (parent.firstChild) {
          parent.firstChild.remove();
        }
        // parent.innerHTML = supportBtnContent;
        setError(getErrorHtmlFromResponse(error.response));
        setSessionData({
          showSupportVerificationError: true,
          supportVerificationError: getErrorHtmlFromResponse(error.response),
        });
        parent
          .querySelector("#btn-support")
          .addEventListener("click", closeSession);
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export const setAxiosHeaders = (token, tokenType = "Bearer") => {
  // delete request.defaults.headers.common["Authorization"];
  // delete identityRequest.defaults.headers.common["Authorization"];

  request.defaults.headers.common["Authorization"] = `${tokenType} ${token}`;

  identityRequest.defaults.headers.common[
    "Authorization"
  ] = `${tokenType} ${token}`;
};
