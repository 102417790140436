import {
  getStoreData,
  getSessionId,
  getProfileToken,
  setSessionData,
  getSessionData,
  getShadowRoot,
  getElement,
  getEventType,
  setIsIdentityCalled,
  getPlatform,
  setPlatform,
  getinitBillingAddr,
  setinitBillingAddr,
  getIpAddress,
  getBrowserDetails,
  setMobileVerificationConsent,
  setUploadDocumentConsent,
} from "./store";
import { hideShowDialog, getDialogState, isFullScreen } from "./DialogService";
import { setMainLoader, setError, isMobile } from "./index";
import { request } from "./axios";
import { setLoading } from "../utils";
import PersonalInfo from "../components/PersonalInfo";
import UploadDocument from "../components/UploadDocument";
import SelfieVerification from "../components/SelfiVerification";
import AddressSelection from "../components/AddressSelection";
import SendOtp from "../components/SendOtp";
import VerifyOtp from "../components/VerifyOtp";
import Success from "../components/Success";
import Error from "../components/Error";
import verifyID from "../components/verifyID";
import VerifyCode from "../components/VerifyCode";
import OtpConfirmation from "../components/OtpConfirmation";
import SupportVerificationError from "../components/SupportVerificationError";
import UpdatePersonalInfo from "../components/UpdatePersonalInfo";
import OwnershipName from "../components/OwnershipName";
import OwnershipAddress from "../components/OwnershipAddress";
import QRForReVerification from "../components/QRForReVerification";
import UploadDocumentSelection from "../components/UploadDocumentSelection";
import MobileVerificationQR from "../components/MobileVerificationQR";
import MoblieVerificationLink from "../components/MoblieVerificationLink";
import MobileVerificationInitiated from "../components/MobileVerificationInitiated";
import DisclosureAndConsent from "../components/DisclosureAndConsent";
let classObjs = {
  DisclosureAndConsentObj: new DisclosureAndConsent(),
  personalInfoObj: new PersonalInfo(),
  uploadDocumentObj: new UploadDocument(),
  selfieVerificationObj: new SelfieVerification(),
  addressSelectionObj: new AddressSelection(),
  sendOtpObj: new SendOtp(),
  verifyOtpObj: new VerifyOtp(),
  successObj: new Success(),
  errorObj: new Error(),
  verifyIDObj: new verifyID(),
  verifyCodeObj: new VerifyCode(),
  otpConfirmationObj: new OtpConfirmation(),
  supportVerificationErrorObj: new SupportVerificationError(),
  updatePersonalInfoObj: new UpdatePersonalInfo(),
  ownershipNameObj: new OwnershipName(),
  ownershipAddressObj: new OwnershipAddress(),
  qrForReVerificationObj: new QRForReVerification(),
  uploadDocumentSelectionObj: new UploadDocumentSelection(),
  mobileVerificationQRObj: new MobileVerificationQR(),
  moblieVerificationLinkObj: new MoblieVerificationLink(),
  mobileVerificationInitiatedObj: new MobileVerificationInitiated(),
};

let defaultIdentityMessage =
  "Please verify your information as it appears on your driver's license.";

let stepMessages = {
  personalInfo:
    "Please input your information as it appears on your driver's license.",
  uploadDocument:
    "Please follow the prompts to upload the front and the back side of your driver's license.",
  selfieVerification:
    "Please follow the prompts to take a live selfie with your camera.",
  addressSelection: `Please select the address associated with you from the options given to continue age verification or use the "Refresh Options" button to reload options in case you don't recognize any address.`,
  sendOtp:
    "It looks like you already have verified your age before! Please choose the Phone Number below to verify a One Time Password and quickly finish verification.",
  verifyOtp: "Please enter the OTP that has been sent to your Phone Number.",
  success: "Successfully verified your age.",
  error: "Unable to verify your age.",
  verifyID: "Please follow the prompts below to continue age verification.",
  otpConfirmation:
    "Please continue to proceed with One Time Password and quickly finish verification.",
  updatePersonalInfo:
    "It seems the personal information does not match with the driver's license. Please update personal information as per the Drivers License.",
  ownershipName:
    "Were you identified by any different First name and Last Name in the past? If so please enter that.",
  ownershipAddress:
    "Please select the address which is/was associated with you to proceed with verification.",
  verifyCode:
    "Please enter the code sent to your email/phone to complete the verification.",
  qrForReVerification:
    "Show the QR code on your screen to complete verification.",
  uploadDocumentSelection:
    "Please follow the prompts below to continue age verification.",
  mobileVerificationQR:
    "Please follow the prompts below to continue age verification.",
  moblieVerificationLink:
    "Please follow the prompts below to continue age verification.",
  mobileVerificationInitiated:
    "Please follow the prompts below to continue age verification.",
};

export let currentStepIndex = 0,
  currentStep = null,
  steps = [];

export const setCurrentStep = async (step) => {
  currentStep = step;

  await classObjs[`${step}Obj`].render();
  setSteps([...steps, step]);
};

export const setSteps = (stepData) => (steps = stepData);

export const isUserVerified = async () => {
  return await request
    .get(`/FinalStatus/${getSessionId()}`)
    .then(async ({ data }) => {
      if (data.data && data.data?.isVerified) {
        return true;
      }
      return false;
    })
    .catch(() => {
      return false;
    });
};

export const nextPrev = async (n) => {
  let { sessionData } = getStoreData();
  setError(false);

  if (n === 0) {
    await decideNextVerificationStep(sessionData);
    setMainLoader(false);
  }

  if (n < 0) {
    if (currentStepIndex === 0) {
      let dialogState = getDialogState();
      closeSession();
      dialogState.errorCallback();
      return false;
    } else {
      currentStep = steps[steps.length - 2];
    }

    steps.pop();
    try {
      await classObjs[`${currentStep}Obj`]?.onBack();
    } catch (e) {}
  }

  if (n > 0) {
    await decideNextVerificationStep(sessionData);
  }

  for (var sIndex = 0; sIndex <= currentStepIndex; sIndex++) {
    let formTabs = getShadowRoot().querySelectorAll("[data-form-tab]");
    formTabs[sIndex].classList.remove("active");
  }

  currentStepIndex += +n;

  if (currentStep === "selfieVerification") {
    const identityModalContainer = getShadowRoot().querySelector(
      "#identityModalContainer"
    );

    identityModalContainer.classList.remove("camera-open");
  }
  showTab();

  const identityLeftMessageEle = getShadowRoot().querySelector("#identityLeftMessage");
  if (identityLeftMessageEle) {
    identityLeftMessageEle.innerText = getPageTitle();
  }
};

const decideNextVerificationStep = async (sessionData) => {
  if (
    'mobileVerificationType' in sessionData && sessionData?.mobileVerificationType !== null  &&
    !isMobile() &&
    sessionData?.nextVerificationStep !== "Success" &&
    sessionData?.nextVerificationStep !== "Failure"
  ) {
    await setCurrentStep("mobileVerificationInitiated");
    return;
  }

  if (sessionData?.nextVerificationStep === "PublicDataVerification") {
    try {
      const response = await request.post(`/publicData/${getSessionId()}`);
      if (response.data?.data?.nextVerificationStep) {
        setSessionData(response.data.data);
        sessionData = getSessionData();
      }
    } catch (error) {
      console.log(error);
      let dialogState = getDialogState();
      closeSession();
      dialogState.errorCallback();
    }
  }
  if (sessionData?.nextVerificationStep === "DisclosureAndConsent") {
    await setCurrentStep("DisclosureAndConsent");
  }
  else if (sessionData?.nextVerificationStep === "CollectPersonalInfo") {
    await setCurrentStep("personalInfo");
  } else if (sessionData?.showSupportVerificationError) {
    setCurrentStep("supportVerificationError");
  } else if (getProfileToken()) {
    setCurrentStep("verifyCode");
  } else if (sessionData?.nextVerificationStep === "UpdatePersonalInfo") {
    await setCurrentStep("updatePersonalInfo");
  } else if (sessionData?.nextVerificationStep === "DocumentVerification") {
    if (isMobile())
      {
      let data = await getConsent("IDPhotoUpload");
      setUploadDocumentConsent(data);

      setCurrentStep("uploadDocument");
    }
    else setCurrentStep("uploadDocumentSelection");
  } else if (
    sessionData?.nextVerificationStep === "SelfieVerification" ||
    sessionData?.nextVerificationStep === "LivenessForVerified" ||
    sessionData?.nextVerificationStep === "LivenessForReVerification"
  ) {
    setSessionData({
      isVerified: sessionData?.nextVerificationStep === "LivenessForVerified" || sessionData?.nextVerificationStep ===  "LivenessForReVerification",
      showRetry : sessionData?.nextVerificationStep === "LivenessForVerified"
    });
    await setCurrentStep("selfieVerification");
  } else if (
    ["OTP", "OTPForVerified", "OTPAfterID", "OTPSkippable"].includes(
      sessionData?.nextVerificationStep
    )
  ) {
    var mobileVerificationConsent = await getConsent("MobileVerification")
    setMobileVerificationConsent(mobileVerificationConsent);

    await request.get(`/Otp/phone/${getSessionId()}`).then(async ({ data }) => {
      setSessionData({
        phones: data.data || [],
        skippable: sessionData?.nextVerificationStep === "OTPSkippable",
        isVerified: sessionData?.nextVerificationStep === "OTPForVerified",
      });
      setCurrentStep("sendOtp");
    });
  } else if (
    ["KBAuth1", "KBAuth2", "KBForVerified"].includes(
      sessionData?.nextVerificationStep
    )
  ) {
    setSessionData({
      isVerified: sessionData?.nextVerificationStep === "KBForVerified",
    });
    await setCurrentStep("addressSelection");
  } else if (sessionData?.nextVerificationStep === "Success") {
    await setCurrentStep("success");
  } else if (sessionData?.nextVerificationStep === "Failure") {
    await setCurrentStep("error");
  } else if (sessionData?.nextVerificationStep === "OwnershipNameValidation") {
    await setCurrentStep("ownershipName");
  } else if (
    sessionData?.nextVerificationStep === "OwnershipAddressValidation"
  ) {
    await setCurrentStep("ownershipAddress");
  } else if (sessionData?.nextVerificationStep === "verifyOtp") {
    setCurrentStep("verifyOtp");
  }
  else if(sessionData?.nextVerificationStep === "QRForReVerification"){

    await setCurrentStep("qrForReVerification");
  }

}

const getPageTitle = () => {
  let { sessionData } = getStoreData(),
    msg;
  if (
    ["sendOtp"].includes(currentStep) &&
    !sessionData?.profileDetails?.isVerified
  ) {
    msg =
      "Please choose the Phone Number below to verify a One Time Password and quickly finish verification.";
  }  else if (currentStep === "addressSelection") {
    let kbType = sessionData.kbType || 0;
    msg =
      kbType === 2
        ? "Please choose Phone Number that is/was associated with you to finish verification."
        : kbType === 1
          ? "Please choose Email that is/was associated with you to finish verification."
          : 'Please select the address associated with you from the options given to continue age verification or use the "Refresh Options" button to reload options in case you don\'t recognize any address.';
  }
  return msg || stepMessages[currentStep] || defaultIdentityMessage;
};

export const showTab = () => {
  let formTabs = getShadowRoot().querySelectorAll("[data-form-tab]"),
    n = currentStepIndex;

  formTabs[n].classList.add("active");

  // setLoading(false);
};

export const resetSteps = () => {
  currentStepIndex = 0;
  currentStep = null;
  steps = [];
};

export const closeSession = async() => {
  let dialogState = getDialogState();
  let currentPage = currentStep;
  let response = await getStatus();
  var selectedElement = getElement();
  var eventType = getEventType();
  var platform = getPlatform();
  let initMagentoSessionOptions = getinitBillingAddr()


  if (isFullScreen() &&  dialogState.redirectUri) {
    // window.location.href = `${
    //   dialogState.redirectUri
    // }?sessionId=${getSessionId()}`;
    let uri = /[?&]/.test(dialogState.redirectUri)
      ? `${dialogState.redirectUri}&sessionId=${getSessionId()}`
      : `${dialogState.redirectUri}?sessionId=${getSessionId()}`;
    window.location.href = uri;
  } else hideShowDialog(false);

  if (currentPage !== "success"&& currentPage !== "error" && dialogState.closeCallback !== undefined){
    dialogState.closeCallback(response);
  }

  if(currentPage === "success" && dialogState.successCallback !== undefined)
    dialogState.successCallback(response)
  else if(currentPage === "error" && dialogState.errorCallback !== undefined)
    dialogState.errorCallback(response)

    if((currentPage ==="success" ||currentPage === "error") && dialogState.completeCallback !== undefined)
    dialogState.completeCallback(response)

    if( selectedElement != null && selectedElement !== undefined && currentPage ==="success"){

  if(platform && platform==='magento2'){
      var n = document.querySelector(".billing-address-same-as-shipping-block");
      var i = document.querySelector(".action-edit-address");
      n && n.style.removeProperty("display");
      i && i.style.removeProperty("display");

      var o = document.querySelector(".billing-address-same-as-shipping-block"),
        r = document.querySelector(".action-edit-address");
      if (o) {
        var c = document.createElement("p"),
          s = document.createElement("a");
        s.style.cursor = "pointer";
        s.onclick = function () {
          o.style.removeProperty("display");
          r && r.style.removeProperty("display");
          c.parentNode.removeChild(c);

        };
        s.innerHTML = "Click to edit address. (Requires re-verification)";
        c.id = "agechecker-reload-container";
        c.appendChild(s);
        o.parentNode.insertBefore(c, o);
        (o.style.display = "none");
        setPlatform(platform);
      }
      r && (r.style.display = "none");
    }
    setIsIdentityCalled(true);

    let element = document.querySelector(selectedElement);
        element.dispatchEvent(new Event(eventType ))
  }

      if(platform && platform==='magento2')
    setinitBillingAddr(initMagentoSessionOptions)
};

const getStatus = () => {
  return request
    .get(`/FinalStatus/${getSessionId()}`)
    .then(({ data }) => {
      let result = data.data;
      return result?.status;
    })
    .catch(({ response }) => {
      setError(response);
    });
};

//Get consent
export const getConsent = async (consentAndPolicy) => {
  let consentLogDetails = {
    consentAndPolicy: consentAndPolicy,
    ipAddress: getIpAddress(),
    deviceType: getBrowserDetails() ? getBrowserDetails().deviceType : "",
    sessionId: getSessionId()
  }
  try {
    var data = await request
      .post(`/TermsAndPrivacy/InitDisclosureAndConsent`, consentLogDetails);
    if (!data.data.data.agreementConsentId) {
      setError("Terms and Privacy configurations are missing or invalid.");
    }
    else
      return data.data.data;
  }
  catch (error) {
    setError(error)
  }
}

export const disclosureAndConsentRequest = (consentDetails, IsAgreed) => {
  request
    .post(`/DisclosureAndConsent/` + getSessionId() + "/?" + setParams(consentDetails, IsAgreed))
    .then(({ data }) => {
      setSessionData(data.data);
      setLoading(false);
      nextPrev(1)
    })
    .catch((error) => {
      console.log(error);
    });
}

export const setParams=(consentDetails, IsAgreed)=>   {
  const params = new URLSearchParams();
  consentDetails.agreementConsentId.forEach(id => params.append("AgreementConsentId", id.toString()));
  params.set("IsAgreed", IsAgreed);
  return params.toString()
}

/**
 * Declined session
 * @param {*} consentDetails 
 */
export const DeclinedTheConsentForm = async (consentDetails) => {
  let requestData = {
    agreementConsentIds: consentDetails.agreementConsentId,
    isDeclined: true,
    sessionId: getSessionId()
  };
  await request
    .put(`/TermsAndPrivacy/DeclinedTheConsentForm/`, requestData)
    .then(({ data }) => {
      console.log(data);
      data.nextVerificationStep = "Failure";
      setSessionData(data);
      setLoading(false);
      nextPrev(1)
    })
    .catch((error) => {
      console.log(error);
    });
}