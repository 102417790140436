/* eslint-disable import/no-unresolved */
// import "@aws-amplify/ui-react/styles.css";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { Amplify } from "aws-amplify";
import awsexports from "../utils/aws-exports";
import React from "react";
import { getLiveSelfiDetails, getSessionData, getShadowRoot } from "../utils/store";
import { DeclinedTheConsentForm, getConsent } from "../utils/StepService";
import { setLoading as setMainLoading } from "../utils/index";
const { forwardRef, useImperativeHandle } = React;

Amplify.configure(awsexports);

export const AwsLiveness = forwardRef((props, ref) => {
  const [loading, setLoading] = React.useState(true);
  const [showLiveness, toggleLiveness] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const [livenessSessionId, setCreateLivenessApiData] = React.useState(null);

  React.useEffect(() => {
    let selfieVerification = getShadowRoot().querySelector(`#${props.formId}`);

    let checkbox = selfieVerification.querySelector("#agreeCheckbox");
    if (checkbox) {
      selfieVerification.querySelector("#btn-continue").disabled = true;
      selfieVerification.querySelector("#canConsent").disabled = true;

      let consentText = selfieVerification.querySelector("#consentText").innerText;
      if (consentText.length < 2) {
        let consentDetails=getLiveSelfiDetails().content;
        if(consentDetails)
          {
          selfieVerification.querySelector("#canConsent").disabled = false;
          selfieVerification.querySelector("#consentText").innerHTML = " " + consentDetails;
        }
        else
          checkbox.disabled = true;
      }
      selfieVerification.querySelector("#agreeCheckbox").addEventListener("change", function () {
        selfieVerification.querySelector("#btn-continue").disabled = !this.checked;
      })
    }

    const fetchCreateLiveness = async () => {
      /*
       * This should be replaced with a real call to your own backend API
       */
      //   await new Promise((r) => setTimeout(r, 2000));
      const sessionData = getSessionData();
      if (sessionData?.livenessSessionId)
        setCreateLivenessApiData(sessionData.livenessSessionId);
      setLoading(false);
    };

    fetchCreateLiveness();
  }, [showLiveness]);

  useImperativeHandle(ref, () => ({
    startLiveness() {
      if (isError) setLivenessError(false);
      const sessionData = getSessionData();
      if (sessionData?.livenessSessionId)
        setCreateLivenessApiData(sessionData.livenessSessionId);
      toggleLiveness(true);
    },

    setLivenessError(val = true) {
      setError(val);
      if (val) setSuccess(false);
    },
  }));

  const cancleSession = async () => {
    setMainLoading(true);
    let consentDetails = await getConsent("LiveSelfieVerification");
    await DeclinedTheConsentForm(consentDetails);
    setMainLoading(false);
  };

  const handleAnalysisComplete = async () => {
    if (isError) setLivenessError(false);
    setSuccess(true);
    toggleLiveness(false);
    // setLoading(true);
    props.successCallback();
  };

  const onUserCancel = async () => {
    toggleLiveness(false);
    setLivenessError(false);
    props.toggleCameraClass(false);

    // show tryAnotherWay link
    const sessionData = getSessionData();
    if (sessionData?.isVerified) {
      let selfieVerification = getShadowRoot().querySelector(`#${props.formId}`);
      if (selfieVerification) {
        let tryAnotherWay = selfieVerification.querySelector("#tryAnotherWay");
        if (tryAnotherWay) tryAnotherWay.style.display = "block";
      }
    }
  };

  const errorCallback = async (errorObj) => {
    setLivenessError(true);
    toggleLiveness(false);
    if(errorObj.state === "CAMERA_ACCESS_ERROR")
      props.errorCallback("Oops! It seems that browser permission was denied previously. You can resolve this by navigating to your 'Website Settings -> Permissions' section and granting access to the camera. Once done, please retry the verification process.")
    else
      props.errorCallback(errorObj.error.message);
  };

  const setLivenessError = async (val = true) => {
    setError(val);
    if (val) setSuccess(false);
  };

  return (
    <>
      <div
        className="face-me-sdk-loading"
        id="faceMeSdkLoading"
        style={{ display: loading ? "flex" : "none" }}
      >
        <svg
          className="svg"
          width="100px"
          height="100px"
          aria-hidden="true"
          role="img"
        >
          <use
            href="#svg__loader"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#svg__loader"
          ></use>
        </svg>
      </div>
      {showLiveness ? (
        <FaceLivenessDetector
          sessionId={livenessSessionId}
          region={"us-east-1"}
          onAnalysisComplete={handleAnalysisComplete}
          onUserCancel={onUserCancel}
          disableInstructionScreen={true}
          onError={errorCallback}
        />
      ) : !isSuccess && !isError ? (
        <div>
          <div
            className="identity__outline-box identity__por identity__r-6"
            id="sdk_block"
            style={{ height: "100%", width: "100%" }}
          >
            <span className="identity__poa identity__left identity__right identity__bottom identity__text-center identity__label">
            To proceed, please review and accept our terms of use and privacy policy. 
            </span>
            <div className="identity__outline-inner identity__height-2x">
              <svg
                id="faceMeSdkCameraIcon"
                className="svg"
                width="60px"
                height="60px"
                aria-hidden="true"
                role="img"
              >
                <use
                  href="#svg__camera"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xlinkHref="#svg__camera"
                ></use>
              </svg>
            </div>
          </div>
          <div className="identity__row" style={{ marginTop: 20 }}>
            <div className="identity__col-12">
              <div className="identity-field floating-field">
                <input type="checkbox" id="agreeCheckbox" className="identity-input-checkbox" />
                <label id="consentText"></label>
              </div>
            </div>
          </div>
          <div className="identity__col-12">
            <div className="identity-field">
              <button id="btn-continue" type="button" onClick={props.initializeLiveness}
                className="identity-btn btn-continue" >Continue
                <span className="loading-icon">
                </span>
              </button>
            </div>
          </div>
          <div className="identity__col-12">
            <div className="identity-field ">
              <button type="button" onClick={cancleSession}
                className="identity-btn identity-btn__secondary"
                id="canConsent">Cancel
                <span className="loading-icon">
                  <svg className="svg" width="20px" height="20px" aria-hidden="true" role="img">
                    <use
                      href="#svg__loader"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      xlinkHref="#svg__loader"
                    ></use>
                  </svg>
                </span>
              </button>
            </div>
          </div>
          </div>
      ) : null}
    </>
  );
});

export default AwsLiveness;
