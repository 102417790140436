// import { getSessionData } from "../utils/store";
import { closeSession, DeclinedTheConsentForm, getConsent, setParams } from "../utils/StepService";
import { getSessionId, getSessionData, getShadowRoot } from "../utils/store";
import { request } from "../utils/axios";
import { setError, getBaseUri, isMobile, setLoading } from "../utils";
import { getDialogState } from "../utils/DialogService";

class Success {
  render = async () => {
    let consentDetails = await this.GetConsentAndDisclosure();

    const result = await this.getFinalStatus();
    const showBtn = this.showContinueButton();
    const showContinueFromWebMessage =this.isMobileVerification();
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");

    if (modalBox.querySelector("#successContent")) return;

    // const sessionData = getSessionData();

    modalBox.insertAdjacentHTML(
      "beforeend",
      `<form class="form-content" data-form-tab id="successContent">
      ${showBtn ? `  <div class="identity__col-12">
                                    <div class="identity-alert__error" id="identity-alert__error" style="display:none;">
                                      <button data-close="close" style="display:none;" type="button">&times;</button>
                                      <svg class="icon" width="20px" height="20px" aria-hidden="true" role="img"><use href="#svg__alert-error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__alert-error"></use></svg>
                                      <div id="identity-alert__message" data-text="text"></div>
                                    </div>
                                  </div>`: ""}
                                <div class="identity__text-center">
                                <svg class="svg" width="74px" height="74px" aria-hidden="true" role="img"><use href="#svg__success" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__success"></use></svg>
                                </div>
                                <h3 class="identity__text-center">Success</h3>
                                <div class="identity__row">
                                <div class="identity__col-12">
                                    <p class="identity__text-center identity__mt0">${ showContinueFromWebMessage ? "You have completed verification successfully. You may now close this page and continue from website." :  `Awesome! ${
                                      result?.personalInfo?.firstName || ""
                                    } ${
        result?.personalInfo?.lastName || ""
        } was successfully verified. ${showBtn ? 'Please "Continue" to move forward.' : ''}</p>`}
                                </div>
                                <div class="identity__col-6">
                                  <div class="identity-field__data">
                                    <label>Account</label>
                                      ${result.accountName}
                                  </div>
                                </div>
                                <div class="identity__col-6">
                                  <!--<div class="identity-field__data">
                                    <label>RCN</label>
                                    000000000
                                  </div>-->
                                </div>
                                <div class="identity__col-6">
                                    <div class="identity-field__data">
                                    <label>Name</label>
                                    ${result?.personalInfo?.firstName || ""} ${
        result?.personalInfo?.lastName || ""
      }
                                    </div>
                                </div>
                                <div class="identity__col-6">
                                    <div class="identity-field__data">
                                    <label>DOB</label>
                                    ${result?.personalInfo?.dob}
                                    </div>
                                </div>
                                 ${showBtn ? `<div class="identity__col-12" >
                                    <div class="identity-field floating-field">
                                    <label for="agreeCheckbox" id="consentText"> ${consentDetails.content || ""} </label>
                                    </div>`: ""}
                                </div>
                                 ${showBtn ? `<div class="identity__col-12">
                                    <div class="identity-field">
                                      <button class="identity-btn" id="btn-continue" type="submit" disabled>Continue</button>
                                    </div>
                                  </div>` : ""}
                                 ${showBtn ? `
                                <div class="identity__col-12">
                                  <div class="identity-field ">
                                    <button type="button"   
                                    class="identity-btn identity-btn__secondary"
                                    id="canConsent" >Cancel
                                     <span class="loading-icon">
                                                <svg class="svg" width="20px" height="20px" aria-hidden="true" role="img">
                                                  <use href="#svg__loader" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__loader"></use>
                                                </svg>
                                              </span></button>
                                </div>` : ""}
                                </div>
                            </form>`
    );

    this.bindEvents(consentDetails);
  };

  getFinalStatus = () => {
    const sessionData = getSessionData();
    if (sessionData?.showSupportVerificationError === false && sessionData?.personalInfo) {
      let result = sessionData
      if (result?.personalInfo?.dob) {
        result.personalInfo.dob = result.personalInfo.dob.replace(
          /^(\d{4})[-\/](\d{1,2})[-\/](\d{1,2})$/,
          '$2-$3-$1'
        )
      }
      return result
    }
    return request
      .get(`/FinalStatus/${getSessionId()}`)
      .then(({ data }) => {
        let result = data.data
        if (result?.personalInfo?.dob) {
          result.personalInfo.dob = result.personalInfo.dob.replace(
            /^(\d{4})[-\/](\d{1,2})[-\/](\d{1,2})$/,
            '$2-$3-$1'
          )
        }
        return result;
      })
      .catch(({ response }) => {
        setError(response);
      });
  };

  formatDate(inputDate) {
    if (!inputDate) return "";
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  }

  async bindEvents(consentDetails) {

    const successForm = getShadowRoot().querySelector("#successContent");
    
    if (this.showContinueButton()){
      
      let consentDetailaUpdate =await  this.GetConsentAndDisclosure();
     
      successForm.querySelector("#consentText").innerHTML=consentDetailaUpdate.content;
      
      const canConsentButton = successForm.querySelector("#canConsent");
      const continueButton = successForm.querySelector("#btn-continue");
      const termsPrivacyCheckbox = successForm.querySelector("#termsprivacy");

      if (canConsentButton) {
        canConsentButton.addEventListener("click", async function () {
          setLoading(true);
        if (consentDetails)
          await DeclinedTheConsentForm(consentDetails)
          setLoading(false);
        });
        canConsentButton.disabled = true;
        if (consentDetails) canConsentButton.disabled = false;
      }

      if (continueButton) {
        continueButton.disabled = true;
      }

      if (termsPrivacyCheckbox) {
        termsPrivacyCheckbox.checked = false; // Reset checkbox state
        termsPrivacyCheckbox.addEventListener("change", function () {
          if (continueButton) {
            continueButton.disabled = !this.checked;
            if (this.checked) {
              request.post(`/FinalConfirmation/${getSessionId()}?${setParams(consentDetails, true)}`
              );
            }
          }
        });
      }
    }


    successForm.addEventListener("submit", (event) => {
      event.preventDefault();
      closeSession();
    });
  }

  showContinueButton() {
    let show = true,
      dialogState = getDialogState(),
      Uri1 = getBaseUri(dialogState.redirectUri),
      Uri2 = window.location.host;

    if (dialogState.redirectUri && Uri1 && Uri1 === Uri2) show = false;
    return show && !this.isMobileVerification();
  }

  isMobileVerification(){
    let sessionData = getSessionData();
    return isMobile() && sessionData?.mobileVerificationType !== null && sessionData?.mobileVerificationType !== undefined;
  }


  //Get personal info consent
  async GetConsentAndDisclosure() {
    let data = await getConsent("FinalConfirmation");

    if (this.showContinueButton() && data) {
      return data;
    }
  }
}

export default Success;
